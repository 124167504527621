body {
  margin: 0;
  background-color: #000000; /*Application Black Theme*/
  color: white;
}

html {
  scroll-behavior: smooth;
}

/*Scrollbar Changes*/
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
