.Home {
    background-image: url('../../assets/Profile/Home_Image.png');
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100vh;
    z-index: -1;
}

.home-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    animation: homeAnimation1 3s;
}

@keyframes homeAnimation1 {

    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.para-content {
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.down-icon {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-content p {
    width: 80%;
    font-size: 22pt;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    color: #FFFFFF;
    font-weight: 300;
    cursor: pointer;
    animation: homeAnimation2 2s infinite;
}

@keyframes homeAnimation2 {
    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }
}

.down-arrow {
    width: 35px;
    height: 35px;
    cursor: pointer;
    animation: bounce 1s infinite;
}

@keyframes bounce {
    0% {
        transform: translateY(0) scaleY(1);
    }

    50% {
        transform: translateY(20px) scaleY(0.8);
    }

    100% {
        transform: translateY(0) scaleY(1);
    }
}

@media only screen and (max-width: 921px) {
    .home-content p {
        font-size: 18pt;
    }

    .down-arrow {
        width: 25px;
        height: 25px;
    }
}

@media only screen and (max-width: 426px) {
    .home-content p {
        font-size: 16pt;
    }
}