.Projects {
    font-family: "Roboto", sans-serif;
    color: #D9D9D9;
}

.workspace {
    background-image: url('../../assets/Workspace/Workspace_View_N°1.jpg');
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.ribat-school {
    background-image: url('../../assets/Ribat_Al_Fath_High_School/Ribat_Al_Fath_High_School_Main_View.jpg');
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.nautic-hotel {
    background-image: url('../../assets/Nautic_Hotel/Nautic_Hotel_By_Night.jpg');
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.slider {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(5px);
    padding-top: 5%;
}

.project-title {
    font-size: 24pt;
    text-align: center;
    padding: 0;
    margin: 0;
    padding-top: 2%;
}

.portLogo {
    width: 130px;
    position: absolute;
    left: 10%;
    top: 12%;
}

@media (max-width: 1025px) {
    .portLogo {
        left: 3%;
    }
}

@media (max-width: 921px) {
    .project-title {
        font-size: 20pt;
    }

    .portLogo {
        display: none;
    }
}

@media (max-width: 426px) {
    .project-title {
        font-size: 16pt;
    }
}