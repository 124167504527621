.About {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding-top: 3%;
    padding-bottom: 3%;
    z-index: 0;
}

.profile-section {
    width: 50%;
    display: flex;
    justify-content: center;
}

.left-content {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.heading1 {
    position: absolute;
    bottom: 120px;
    left: -80px;
    color: #fff;
    font-size: 24pt;
    font-family: 'Roboto', sans-serif;
}

.heading2 {
    position: absolute;
    bottom: 80px;
    left: -80px;
    color: #9CC3E5;
    font-size: 24pt;
    font-family: 'Roboto', sans-serif;
}

.profile-pic {
    width: 400px;
    height: 100%;
}

.about-section {
    width: 50%;
    padding-right: 5%;
}

.about-section h3 {
    color: #9CC3E5;
    font-weight: 600;
    font-size: 22pt;
    text-align: center;
    font-family: 'Roboto', sans-serif;
}

.about-section p {
    font-size: 16pt;
    text-align: justify;
    font-family: Calibri;
    line-height: 30px;
}

.view-cv {
    float: right;
    border-radius: 30px;
    padding: 10px 30px;
    background-color: #9CC3E5;
    color: white;
    cursor: pointer;
    font-size: 12pt;
    margin-right: 2%;
    margin-top: -10px;
    font-family: 'Roboto', sans-serif;
}

.special-span {
    color: #9CC3E5;
}

.view-cv:hover {
    transform: scale(1.1);
}

@media only screen and (max-width: 1301px) {
    .About {
        height: 100%;
    }
    
    .about-section h3 {
        font-size: 20pt;
    }

    .about-section p {
        font-size: 16pt;
        line-height: 25px;
    }
}

@media only screen and (max-width: 921px) {
    .About {
        display: block;
    }

    .profile-section {
        width: 100%;
    }

    .profile-pic {
        width: 300px;
    }

    .about-section {
        width: 90%;
        height: max-content;
        padding-left: 5%;
        margin-bottom: 5%;
    }

    .about-section h3 {
        font-size: 18pt;
    }

    .about-section p {
        font-size: 14pt;
        line-height: 25px;
    }

    .view-cv {
        padding: 8px 24px;
        font-size: 10pt;
        float: none;
    }

    .heading1 {
        bottom: 80px;
        left: -100px;
        font-size: 20pt;
    }

    .heading2 {
        bottom: 40px;
        left: -100px;
        font-size: 20pt;
    }
}

@media only screen and (max-width: 426px) {
    .profile-pic {
        width: 200px;
    }

    .about-section h3 {
        font-size: 16pt;
    }

    .about-section p {
        font-size: 12pt;
        line-height: 20px;
    }

    .view-cv {
        padding: 8px 20px;
        font-size: 8pt;
        float: none;
    }

    .heading1 {
        bottom: 40px;
        left: -30px;
        font-size: 16pt;
    }

    .heading2 {
        bottom: 20px;
        left: -30px;
        font-size: 16pt;
    }
}