.Portfolio {
    background-image: url('../../assets/Nautic_Hotel/Nautic_Hotel_By_Night.jpg');
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100vh;
    z-index: 0;
    font-family: "Roboto", sans-serif;
    color: #D9D9D9;
}

.portfolio-words {
    width: 50%;
    padding-top: 5%;
    padding-left: 5%;
}

.portfolio-word {
    font-size: 120px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.portfolio-word span {
    width: 21%;
    display: inline-block;
    position: relative;
}

.port {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 3px solid white;
}

.portfolio-projects {
    width: 40%;
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.word-spilt {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vertical-word {
    display: flex;
    flex-direction: column;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


.letter {
    font-size: 24px;
    margin-bottom: 20px;
    animation: fadeIn 0.5s ease forwards;
    animation-delay: calc(2s * var(--index));
}

.portfolio-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(5px);
    opacity: 0;
    transition: opacity 2s ease;
}

.portfolio-heading.visible {
    opacity: 1;
}

.image {
    width: 120px;
    height: 120px;
}

.image-container {
    position: relative;
    display: inline-block;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 96%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.image-container:hover .overlay i {
    opacity: 1;
}

hr {
    width: 680px;
}

.overlayed-image {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10%;
}

.portfolio-words p {
    letter-spacing: 5px;
    margin-left: 8%;
    font-size: 16pt;
}

@media (max-width: 921px) {
    hr {
        display: none;
    }

    .Portfolio {
        height: 100%;
    }

    .portfolio-words {
        width: 100%;
        padding: 0;
    }

    .portfolio-word span {
        width: 25%;
    }

    .portfolio-heading {
        display: block;
    }

    .word-spilt {
        display: none;
    }

    .portfolio-projects {
        width: 100%;
        padding-top: 5%;
        display: flex;
        flex-direction: row;
    }

    .overlayed-image {
        display: flex;
        width: 30%;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10%;
    }

}

@media (max-width: 426px) {
    .portfolio-word {
        font-size: 80px;
    }

    .port {
        width: 60px;
        height: 60px;
    }

    .portfolio-words p {
        font-size: 12pt;
    }

    .project-name {
        font-size: 8pt;
    }

    .image {
        width: 100px;
        height: 100px;
    }
}