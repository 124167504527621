.carousel-container {
  position: relative;
  width: 80%;
  margin: 0 auto;
  padding: 2%;
  padding-top: 5%;
  padding-bottom: 5%;
  font-family: "Roboto", sans-serif;
}

.carousel-slider {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slides{
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
}

.slide {
  width: 500px;
  height: 350px;
  margin-left: 1%;
  margin-right: 1%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

@keyframes slideBackgroundRight {
  0% {
    transform: translateX(5%) rotateY(30deg) translateZ(100px);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0%) rotateY(0deg) translateZ(0);
    opacity: 1;
  }
}

@keyframes slideBackgroundLeft {
  0% {
    transform: translateX(-5%) rotateY(-30deg) translateZ(100px);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0%) rotateY(0deg) translateZ(0px);
    opacity: 1;
  }
}

.slide.focused {
  width: 600px;
  height: 400px;
  z-index: 1;
}

.slidePrev {
  width: 350px;
  height: 250px;
  opacity: 0.5;
  transform-origin: right center;
  transform: perspective(1000px) rotateY(-30deg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.slideNext {
  width: 350px;
  height: 250px;
  opacity: 0.5;
  transform-origin: left center;
  transform: perspective(1000px) rotateY(30deg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

.thumbnails {
  display: flex;
  background-image: linear-gradient(to right, transparent, #D9D9D9, transparent);
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-image: linear-gradient(to right, transparent, #D9D9D9, transparent) 1;
  justify-content: center;
  margin-top: 20px;
  padding: 10px;
}

.thumbnail-heading {
  width: 20%;
  color: #D9D9D9;
  font-size: 14pt;
}

.thumbnails-container {
  width: 80%;
  overflow-x: scroll;
  max-height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.thumbnail-images {
  white-space: nowrap;
}

.thumbnails img {
  width: 100px;
  height: 100px;
  margin: 0 5px;
  cursor: pointer;
  display: inline-block;
}

.thumbnails img.active {
  border: 2px solid white;
}

.image-title {
  font-size: 16pt;
  text-align: center;
  padding: 0;
  margin: 0;
  padding-top: 1%;
  padding-bottom: 2%;
}

.white-overlay {
  background-color: rgb(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
  width: 100%;
  height: 100%;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.white-overlay p {
  font-size: 13pt;
  margin: 0;
  padding: 10px;
  text-align: justify;
  color: #4b4b4b;
}

.cancel-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  color: white;
  width: 20px;
  height: 20px;
}

.up-arrow {
  width: 20px;
  height: 20px;
  animation: bounces 1s infinite;
}

@keyframes bounces {
  0% {
    transform: translateY(0) scaleY(1) rotate(180deg);
  }

  50% {
    transform: translateY(-5px) scaleY(0.9) rotate(180deg);
  }

  100% {
    transform: translateY(0) scaleY(1) rotate(180deg);
  }
}


.no-overlay p {
  font-size: 10pt;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

.no-overlay {
  display: flex;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
}

.leftArrow {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.rightArrow {
  width: 50px;
  height: 50px;
  cursor: pointer;
  transform: rotate(180deg);
}

@media screen and (max-width: 921px) {

  .slidePrev,
  .slideNext {
    display: none;
  }

  .thumbnails {
    display: none;
  }

  .leftArrow {
    width: 30px;
    height: 30px;
  }

  .rightArrow {
    width: 30px;
    height: 30px;
  }

  .image-title {
    font-size: 14pt;
  }

  .no-overlay p {
    font-size: 8pt;
  }

  .up-arrow {
    width: 15px;
    height: 15px;
  }

}

@media screen and (max-width: 426px) {
  .slide {
    width: 300px;
    height: 200px;
  }

  .slides{
    height: 200px;
  }

  .slide.focused {
    width: 300px;
    height: 200px;
    z-index: 1;
  }

  .white-overlay p {
    font-size: 8pt;
    ;
  }

  .cancel-btn {
    width: 15px;
    height: 15px;
  }

  .image-title {
    height: 50px;
  }

}